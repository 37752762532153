import React from "react";
import "./slider-title.styles.scss";

const SliderTitle = ({ content }) => {
	const { title, description, button } = content;
	return (
		<div className="content">
			<h1 className="title">{title}</h1>
			<p className="description">{description}</p>
			{button ? (
				<div className="button-wrapper">
					<a className="btn" href={button.path}>
						<span>{button.text}</span>
					</a>
				</div>
			) : null}
		</div>
	);
};

export default SliderTitle;
