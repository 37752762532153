import React from "react";
import "./clients-comments.styles.scss";
import image from "../../assets/icons/home/TESTIMONIALES/icon_5.svg";
import CommentsCarousel from "../comments-carousel/comments-carousel.component";

const ClientsComments = ({ content }) => {
	const { clientsComments, description, title } = content;
	return (
		<div className="client-comments section">
			<div className="client-comments__title">
				<div className="content-title">
					<img alt="section icon" src={image} />
					<h2 className="title">{title}</h2>
					<p className="description">{description}</p>
				</div>
			</div>
			<div className="client-comments__logos">
				<CommentsCarousel clientComments={clientsComments} />
			</div>
		</div>
	);
};

export default ClientsComments;
