import * as firebase from "firebase/app";
import "firebase/firestore";

const types = {
	vehicles: "SUBMITED_VEHICLES",
	persons: "SUBMITED_PERSONS",
	bonds: "SUBMITED_BONDS",
	whealth: "SUBMITED_WHEALTH",
};
export const submitForm = (contentObject, formType) => {
	return (dispatch) => {
		const contentRef = firebase.firestore().collection("formsSubmitions");

		contentRef
			.add({ ...contentObject, formSubmitionType: formType })
			.then((data) => {
				dispatch({ type: types[formType], payload: true });
			})
			.catch((err) => {
				console.error(err);
			});
	};
};
