import React, { useState } from "react";
import "./header.styles.scss";
import { Link } from "react-router-dom";
import { ContactWhatsapp } from "../index";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import mobileMenu from "../../assets/icons/home/HERO/menu_active.svg";
import { setState } from "expect";

const Header = ({ logo, phone }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	return (
		<div className="header wrapper">
			<div className="logo-header">
				<Link to="/">
					<img className="logo" alt="Company Logo" src={`${logo.url}`} />
				</Link>
			</div>
			<ul className="menu-items">
				<li className="menu-items__item">
					<Link to="/">Inicio</Link>
				</li>
				<li className="menu-items__item">
					<Link to="/experiencia-jbl">Experiencia JBL</Link>
				</li>
				<li className="menu-items__item">
					<a href="#!">Nuestros Productos</a>
					<ul className="menu-items__item__sub-menu">
						<li className="menu-items__item__sub-menu__item">
							<Link to="/nuestros-productos/personas">Personas</Link>
						</li>
						<li className="menu-items__item__sub-menu__item">
							<Link to="/nuestros-productos/automoviles">Automóviles</Link>
						</li>
						<li className="menu-items__item__sub-menu__item">
							<Link to="/nuestros-productos/patrimoniales">Patrimoniales</Link>
						</li>
						<li className="menu-items__item__sub-menu__item">
							<Link to="/nuestros-productos/fianzas">Fianzas</Link>
						</li>
					</ul>
				</li>
				<li className="menu-items__item">
					<Link to="/siniestros">Siniestros</Link>
				</li>
			</ul>
			<div className="right-item">
				<div className="contact-info">
					<ContactWhatsapp number={phone} />
				</div>
				<div
					className="menu-mobile-button"
					onClick={() => setMenuOpen(!menuOpen)}
				>
					<img alt="icon for open menu mobile" src={mobileMenu} />
				</div>
			</div>

			<div className={`mobile-menu ${menuOpen ? "active" : ""}`}>
				<ul className="mobile-menu__menu-items">
					<li
						className="mobile-menu__menu-items__item"
						onClick={() => setMenuOpen(!menuOpen)}
					>
						<Link to="/">Inicio</Link>
					</li>
					<li
						className="mobile-menu__menu-items__item"
						onClick={() => setMenuOpen(!menuOpen)}
					>
						<Link to="/experiencia-jbl">Experiencia JBL</Link>
					</li>
					<li className="mobile-menu__menu-items__item">
						<a href="#!">Nuestros Productos</a>
						<ul className="mobile-menu__menu-items__item__sub-menu">
							<li
								className="mobile-menu__menu-items__item__sub-menu__item"
								onClick={() => setMenuOpen(!menuOpen)}
							>
								<Link to="/nuestros-productos/personas">Personas</Link>
							</li>
							<li
								className="mobile-menu__menu-items__item__sub-menu__item"
								onClick={() => setMenuOpen(!menuOpen)}
							>
								<Link to="/nuestros-productos/automoviles">Automóviles</Link>
							</li>
							<li
								className="mobile-menu__menu-items__item__sub-menu__item"
								onClick={() => setMenuOpen(!menuOpen)}
							>
								<Link to="/nuestros-productos/patrimoniales">
									Patrimoniales
								</Link>
							</li>
							<li
								className="mobile-menu__menu-items__item__sub-menu__item"
								onClick={() => setMenuOpen(!menuOpen)}
							>
								<Link to="/nuestros-productos/fianzas">Fianzas</Link>
							</li>
						</ul>
					</li>
					<li
						className="mobile-menu__menu-items__item"
						onClick={() => setMenuOpen(!menuOpen)}
					>
						<Link to="/siniestros">Siniestros</Link>
					</li>
				</ul>
			</div>
		</div>
	);
};

const mapStateToProps = (state, props) => {
	const content = state.firestore.data.content?.home;
	let logo, phone;
	if (content && content.slider) {
		logo = content.slider.logo;
		phone = content.slider.phone;
	}
	return {
		...state,
		logo: logo || { url: "", name: "" },
		phone: phone || "",
	};
};

export default compose(
	firestoreConnect(() => ["content"]),
	connect(mapStateToProps)
)(Header);
