import React from "react";
import { ReactComponent as Logo } from "../../assets/icons/home/HERO/jbl_logo.svg";

const Loader = () => {
	return (
		<div
			className="animated fadeIn"
			style={{
				backgroundColor: "#CDCDCD",
				zIndex: 2500,
				position: "absolute",
				opacity: 1,
				width: "100vw",
				height: "100vh",
				top: 0,
				left: 0,
			}}
		>
			<div
				style={{
					top: "50%",
					left: "50%",
					margin: 0,
					position: "absolute",
					transform: "translate(-50%, -50%)",
				}}
			>
				<div className="animated infinite pulse">
					<Logo />
				</div>
			</div>
			<div
				style={{
					zIndex: 2500,
					top: "60%",
					left: "50%",
					transform: "translate(-50%, -60%)",
					position: "absolute",
				}}
			>
				<p style={{ color: "white" }}> Cargando... </p>
			</div>
		</div>
	);
};

export default Loader;
