import React from "react";
import "./info-location.styles.scss";
import icon from "../../assets/icons/home/HERO/info_active.svg";

const InfoLocation = ({ content, number }) => {
	return (
		<div className="widget location-slider">
			<div className="img">
				<img className="icon" alt="icon" src={icon} />
			</div>
			<div className="text">
				<a
					className="location"
					target="blank"
					href={`http://maps.google.com/?q=${content}`}
				>
					{content}{" "}
				</a>
				+<a href={`tel:${number}`}>{number}</a>
			</div>
		</div>
	);
};

export default InfoLocation;
