import React from "react";
import "./what-you-need.styles.scss";
import image from "../../assets/icons/home/TIPOS_DE_SEGURO/icon_6.svg";
import { Redirect, Route } from "react-router-dom";

const WhatYouNeed = ({ content, history }) => {
	const { title, description, subTitle, items } = content;

	const redirectFunction = (path) => {
		history.push(`/nuestros-productos/${path}`);
	};

	return (
		<div className="what-you-need section">
			<div className="what-you-need__title">
				<div className="content-title">
					<img alt="section icon" src={image} />
					<h2 className="title">{title}</h2>
					<p className="description">{description}</p>
					<p className="sub-tile">{subTitle}</p>
				</div>
			</div>
			<div className="what-you-need__cards">
				{items.map((item, index) => {
					return (
						<TypesCards
							item={item}
							key={`${index}-${Math.random()}`}
							redirect={redirectFunction}
						/>
					);
				})}
			</div>
		</div>
	);
};

const TypesCards = ({ item, redirect }) => {
	return (
		<div
			className="type-card"
			onClick={() => redirect(item.path)}
			style={{ backgroundImage: `url(${item.background.url})` }}
		>
			<img src={item.image.value} alt="icon insurance type" className="icon" />
			<span className="card-title">{item.title}</span>
		</div>
	);
};

export default WhatYouNeed;
