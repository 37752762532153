import React from "react";
import "./our-differences.styles.scss";

const OurDifferences = ({ content }) => {
	const { title, description, button, image, items } = content;
	return (
		<div className="our-differences section">
			<div
				className="our-differences__info"
				style={{ backgroundImage: `url('${image.url}')` }}
			>
				<div className="our-differences__info__content">
					<h2 className="title">{title}</h2>
					<p className="description">{description}</p>
					{button ? (
						<div className="button-wrapper">
							<a className="btn" href={button.path}>
								<span>{button.text}</span>
							</a>
						</div>
					) : null}
				</div>
			</div>
			<div className="our-differences__cards-section">
				<div className="our-differences__cards-section__cards">
					{items.map((item, index) => (
						<Card key={`${index}+${Math.random()}`} content={item} />
					))}
				</div>
			</div>
		</div>
	);
};

const Card = ({ content }) => (
	<div className="card">
		<img alt="icon" src={content.image.value} />
		<h3>{content.title}</h3>
		<p>{content.description}</p>
	</div>
);

export default OurDifferences;
