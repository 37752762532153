import React, { Component } from "react";
import "./jbl-experience.styles.scss";
import {
	SliderInternalPages,
	Loader,
	ContactHome,
} from "../../components/index";

class JblExperience extends Component {
	render() {
		const { content, contact } = this.props;

		return content ? (
			<div className="wrapper jbl-experience">
				<div className="jbl-experience__slider">
					<SliderInternalPages
						background={content.slider.background}
						description={content.slider.description}
						title={content.slider.title}
						pageIndicator={"Quiénes somos"}
						smallText={true}
					/>
				</div>
				<div className="jbl-experience__history">
					<div className="jbl-experience__history__title">
						<img src={content.description.icon.value} alt="icon" />
						<h1 className="title">{content.description.title}</h1>
					</div>
					<div className="jbl-experience__history__text">
						<p className="description">{content.description.description}</p>
					</div>
				</div>
				<div className="jbl-experience__points">
					<div className="jbl-experience__points__grid">
						<h2 className="title">{content.points.title}</h2>
						<div className="grid-wrapper">
							{content.pointsArray.map((ele, index) => (
								<Card key={`${index}-${Math.random()}`} content={ele} />
							))}
						</div>
					</div>
					<div
						className="jbl-experience__points__image"
						style={{ backgroundImage: `url(${content.points.image.url})` }}
					></div>
				</div>
				{/* <div className="jbl-experience__our-team">
					<img
						className="jbl-experience__our-team__icon"
						src={content.ourTeam.icon.value}
						alt="icon"
					/>
					<h2 className="title">{content.ourTeam.title}</h2>

					<div className="jbl-experience__our-team__cards">
						{content.teamArray.map((member, index) => (
							<div key={`${index}-${Math.random()}`} className="card-wrapper">
								<div
									className="card-wrapper__image"
									style={{ backgroundImage: `url(${member.background.url})` }}
								></div>
								<div className="card-wrapper__content">
									<span className="name">{member.title}</span>
									<br></br>
									<span className="description">{member.description}</span>
								</div>
							</div>
						))}
					</div>
				</div> */}
				<div
					className="jbl-experience__contact"
					style={{
						backgroundImage: ` url(${contact.background.url}) `,
					}}
				>
					<ContactHome content={contact} number={contact.number} />
				</div>
			</div>
		) : (
				<Loader />
			);
	}
}

const Card = ({ content }) => (
	<div className="card">
		<img alt="icon" src={content.icon.value} />
		<h3>{content.title}</h3>
		<p>{content.description}</p>
	</div>
);

export default JblExperience;
