import React, { Component } from "react";
import "./homepage.styles.scss";
import {
	HomeSlider,
	SliderTitle,
	InfoLocation,
	SocialButtons,
	OurDifferences,
	OurAllies,
	ClientsComments,
	WhatYouNeed,
	ExpertsOpinions,
	ContactHome,
	Loader,
} from "../../components/index";

class HomePage extends Component {
	render() {
		const { content, history } = this.props;
		return content ? (
			<div className="home wrapper">
				<div className="home__slider">
					<HomeSlider images={content.slider.imageSlider} />
					<SliderTitle content={content.slider} />
					<InfoLocation
						className="home__slider__info-location"
						content={content.slider.address}
						number={content.slider.phone}
					/>
					<SocialButtons />
				</div>
				<div className="home__our-differences">
					<OurDifferences content={content["our-differences"]} />
				</div>
				<div className="home__our-allies">
					<OurAllies content={content["allies"]} />
				</div>
				<div className="home__clients-comments">
					<ClientsComments content={content["clients-comments"]} />
				</div>
				<div className="home__what-you-need">
					<WhatYouNeed content={content["your-needs"]} history={history} />
				</div>
				<div className="home__experts-opinions">
					<ExpertsOpinions content={content["experts"]} />
				</div>
				<div
					className="home__contact-home"
					style={{
						backgroundImage: ` url(${content["contact-home"].background.url}) `,
					}}
				>
					<ContactHome
						content={content["contact-home"]}
						number={content.slider.phone}
					/>
				</div>
			</div>
		) : (
				<Loader />
			);
	}
}

export default HomePage;
