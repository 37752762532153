import React from "react";
import "./our-allies.styles.scss";
import { LogosCarousel } from "../index";

const OurAllies = ({ content }) => {
	const { images, title } = content;
	return (
		<div className="allies section">
			<div className="allies__title">
				<h2 className="title">{title}</h2>
			</div>
			<div className="allies__logos">
				<LogosCarousel images={images} />
			</div>
		</div>
	);
};

export default OurAllies;
