import React from "react";
import "./experts-opinions.styles.scss";
import image from "../../assets/icons/home/TIPS/icon_7.svg";

const ExpertsOpinions = ({ content }) => {
	const { title, subTitle, description, background, expertsOpinions } = content;

	return (
		<div className="experts-opinions section">
			<div className="experts-opinions__title">
				<div className="content-title">
					<img alt="section icon" src={image} />
					<h2 className="title">{title}</h2>
				</div>
			</div>
			<div className="experts-opinions__content">
				<div className="experts-opinions__content__card">
					<div
						className="card-wrapper"
						style={{ backgroundImage: `url(${background.url})` }}
					>
						<div className="card-wrapper__content">
							<h2 className="section-title">{subTitle}</h2>
							<hr />
							<p>{description}</p>
						</div>
					</div>
				</div>
				<div className="experts-opinions__content__opinions">
					{expertsOpinions.map((opinion, index) => {
						return (
							<div
								key={`${index}-${Math.random()}`}
								className="opinion-wrapper"
							>
								<h2 className="section-title">{opinion.title}</h2>
								<p className="description">{opinion.message}</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ExpertsOpinions;
