import React from "react";
import Slider from "react-animated-slider";
import "./home-slider.styles.scss";
import "react-animated-slider/build/horizontal.css";

const HomeSlider = ({ images }) => {
	return (
		<Slider className="slider-wrapper" autoplay={5000} infinite={true}>
			<div
				key={Math.random()}
				className="slider-content"
				style={{ background: `url('${images ? images.url : ''}') no-repeat center center` }}
			></div>
		</Slider>
	);
};

export default HomeSlider;
