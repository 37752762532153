import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { reduxFirestore, firestoreReducer } from "redux-firestore";
import { firebaseReducer, getFirebase } from "react-redux-firebase";
import firebase from "../firebase/firebase.utils";
import thunk from "redux-thunk";
import formsReducer from "./reducer/forms.reducer";

firebase.firestore();

const rootReducer = combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	formsStatus: formsReducer,
});

const createStoreWithFirebase = compose(
	applyMiddleware(thunk.withExtraArgument(getFirebase)),
	reduxFirestore(firebase)
)(createStore);

const initialState = {};

export default createStoreWithFirebase(rootReducer, initialState);
