import React from "react";
import "./404-template.styles.scss";
import image from "../../assets/icons/home/HERO/jbl_logo.svg";
import { Loader } from "../../components/index";

const Template404 = ({ history, content }) => {
	return content && content.home ? (
		<div className="not-found wrapper">
			<div className="not-found__message">
				<img src={image} alt="Logo jbl" />
				<h1 className="title">Página no encontrada</h1>
			</div>
			<div className="button-wrapper" onClick={() => history.push("/")}>
				<a className="btn" href={"#!"}>
					<span>Volver</span>
				</a>
			</div>
		</div>
	) : (
		<Loader />
	);
};

export default Template404;
