const initState = {
	submitedVehicles: false,
	submitedPersons: false,
	submitedBonds: false,
	submitedWhealth: false,
};

const formsReducer = (state = initState, action) => {
	switch (action.type) {
		case "SUBMITED_VEHICLES":
			return {
				...state,
				submitedVehicles: true,
			};
		case "SUBMITED_PERSONS":
			return {
				...state,
				submitedPersons: true,
			};
		case "SUBMITED_BONDS":
			return {
				...state,
				submitedBonds: true,
			};
		case "SUBMITED_WHEALTH":
			return {
				...state,
				submitedWhealth: true,
			};

		default:
			return state;
	}
};

export default formsReducer;
