import React from "react";
import "./footer.styles.scss";
import { InfoLocation, SocialButtons } from "../../components/index";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";

const Footer = ({ logo, address, phone, footerContent }) => (
	<div className="footer wrapper">
		<div className="footer-content">
			<div className="menu-section">
				<div className="top">
					<h2 className="top__title">{footerContent.title}</h2>
					<p className="top__text">{footerContent.description}</p>
				</div>
				<div className="bottom">
					<div className="menu">
						<div className="head">
							<div>
								<Link to="/nuestros-productos/automoviles">
									<h3>Productos</h3>
								</Link>
							</div>
							<div>
								<Link to="/experiencia-jbl">
									<h3>Experiencia JBL</h3>
								</Link>
							</div>
							<div>
								<Link to="/siniestros">
									<h3>Siniestros</h3>
								</Link>
							</div>
						</div>
						<div className="links">
							<div className="individuals">
								{/* <span>Para ti</span> */}
								<ul>
									<li className="menu__item">
										<Link to="/nuestros-productos/personas">Personas</Link>
									</li>
									<li className="menu__item">
										<Link to="/nuestros-productos/automoviles">
											Automóviles
										</Link>
									</li>
									<li className="menu__item">
										<Link to="/nuestros-productos/patrimoniales">
											patrimoniales
										</Link>
									</li>
									<li className="menu__item">
										<Link to="/nuestros-productos/fianzas">Fianzas</Link>
									</li>
								</ul>
							</div>
							{/* <div className="company">
								<span>Para empresas</span>
								<ul>
									<li className="menu__item">
										<Link to="/nuestros-productos/persons">empleados</Link>
									</li>
									<li className="menu__item">
										<Link to="/our-products/vehicles">flotas</Link>
									</li>
									<li className="menu__item">
										<Link to="/our-products/whealth">patrimoniales</Link>
									</li>
									<li className="menu__item">
										<Link to="/our-products/bonds">Fianzas</Link>
									</li>
								</ul>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<div className="company-section">
				<div className="company-section__logo">
					<img src={logo.url} alt="JBL Logo" className="footer-logo" />
					<div className="social-footer">
						<SocialButtons footer={true} />
					</div>
				</div>
				<InfoLocation content={address} number={phone} />
			</div>
		</div>
		<div className="footer-copyright">
			<span>{footerContent.copyrights}</span>
		</div>
	</div>
);

const mapStateToProps = (state, props) => {
	const content = state.firestore.data.content?.home;
	const footerContent = state.firestore.data.content?.footer;
	let logo, address, phone;
	if (content && content.slider) {
		logo = content.slider.logo;
		address = content.slider.address;
		phone = content.slider.phone;
	}
	return {
		...state,
		logo: logo || { url: "", name: "" },
		address: address || "",
		phone: phone || "",
		footerContent: footerContent || {},
	};
};

export default compose(
	firestoreConnect(() => ["content"]),
	connect(mapStateToProps)
)(Footer);
