import React, { Component } from "react";
import "./social-buttons.styles.scss";
import linkedin from "../../assets/icons/home/HERO/linkedin_active.svg";
import facebook from "../../assets/icons/home/HERO/facebook_active.svg";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

class SocialButtons extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		console.log(this.props.footer)
		return this.props.content ? (
			<>
				{this.props.footer && (this.props.content.linkedIn !== '#' || this.props.content.facebook !== '#') ? <h2>Follow Us</h2> : null}
				<div className="widget social-slider">
					<div className="icon-wrapper">
						{this.props.content.linkedIn !== '#' ?
							<a href={this.props.content.linkedIn} target="blank">
								<img src={linkedin} alt="linked in icon" className="icon" />
							</a>
							: null}
						{this.props.content.facebook !== '#' ?
							<a href={this.props.content.facebook} target="blank">
								<img src={facebook} alt="facebook icon" className="icon" />
							</a>
							: null}

					</div>
				</div>
			</>
		) : null;

	}
}

const mapStateToProps = (state, props) => {
	const content = state.firestore.data.content?.socials;
	return {
		...state,
		content: content,
		footer: props.footer
	};
};

export default compose(
	firestoreConnect(() => ["content"]),
	connect(mapStateToProps)
)(SocialButtons);
