import React, { Component } from "react";
import "./styles/scss/index.scss";
import { Route, Switch } from "react-router-dom";
import {
	HomePage,
	ContactPage,
	OurProducts,
	JblExperience,
	InsuranceForms,
	Template404,
} from "./views/index";
import { Header, Footer, Loader } from "./components/index";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import ScrollToTop from "./helpers/ScrollToTop";

class App extends Component {
	render() {
		const { home, contact, forms } = this.props.content;
		const OurProductsContent = this.props.content["our-products"];
		const JblExperienceContent = this.props.content["jbl-experience"];

		return (
			<div className="main-wrapper">
				<Header />
				<ScrollToTop />
				<Switch>
					<Route
						exact
						path="/"
						component={({ history }) => (
							<HomePage content={home} history={history} />
						)}
					/>
					<Route
						exact
						path="/experiencia-jbl"
						component={() => (
							<JblExperience
								content={JblExperienceContent}
								contact={
									home
										? {
												...home["contact-home"],
												number: home.slider.phone,
										  }
										: null
								}
							/>
						)}
					/>
					<Route
						exact
						path="/formularios/:id"
						component={({ match, history }) => (
							<InsuranceForms
								id={match.params.id}
								content={forms}
								history={history}
							/>
						)}
					/>
					<Route
						exact
						path="/nuestros-productos/:id"
						component={({ match, history }) => (
							<OurProducts
								content={OurProductsContent}
								id={match.params.id}
								history={history}
							/>
						)}
					/>
					<Route
						exact
						path="/siniestros"
						component={() => <ContactPage content={contact} />}
					/>

					<Route
						component={({ history }) => (
							<Template404 history={history} content={this.props.content} />
						)}
					/>
				</Switch>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		...state,
		content: state.firestore.data.content || {},
		formsStatus: state.formsStatus || {},
	};
};

export default compose(
	firestoreConnect(() => ["content"]),
	connect(mapStateToProps)
)(App);
